<template>
    <div v-if="!isMobile" class="card-cnt mb-3">
        <CardHeader
            :funcionario="funcionario"
            :confirmado="confirmado"
            :tempoExpirado="tempoExpirado"
            :aguardandoConfirmacaoFuncionario="aguardandoConfirmacaoFuncionario"
        />
        <TabelaExames
            v-if="!confirmado && !aguardandoConfirmacaoFuncionario"
            @confirmado="confirmar"
            :funcionario="funcionario"
            :tempoExpirado="tempoExpirado"
            :aguardandoConfirmacaoFuncionario="aguardandoConfirmacaoFuncionario"
        />
    </div>
    <div v-else class="card-cnt mb-3">
        <FuncionarioMobile
            @confirmado="confirmar"
            :funcionario="funcionario"
            :confirmado="confirmado"
            :tempoExpirado="tempoExpirado"
            :aguardandoConfirmacaoFuncionario="aguardandoConfirmacaoFuncionario"
        />
    </div>
</template>

<script>
import CardHeader from './components/CardHeader.vue';
import TabelaExames from '../../../TabelaExames/Index.vue';
import { useWindowSize } from '@vueuse/core';
import FuncionarioMobile from './components/FuncionarioMobile.vue';
import EnumSituacao from '@/enums/EnumAgendamentoConfirmacaoPrestador.js';

export default {
    components: { FuncionarioMobile, TabelaExames, CardHeader },
    props: {
        funcionario: { type: Object, required: true }
    },
    setup() {
        const { width } = useWindowSize();
        return { width };
    },
    data() {
        return {
            confirmado: false,
            tempoExpirado: false,
            intervalo: null
        };
    },
    computed: {
        padding() {
            return this.confirmado ? '8px 16px' : '16px';
        },
        aguardandoConfirmacaoFuncionario() {
            return this.funcionario.situacao === EnumSituacao.AGUARDANDO_CONFIRMACAO_FUNCIONARIO;
        },
        isMobile() {
            return this.width < 576;
        }
    },
    methods: {
        confirmar() {
            this.confirmado = true;
        },
        monitorarTempoConfirmacao() {
            let tempoParaAgendar = this.funcionario.tempoParaAgendar;
            if (tempoParaAgendar <= 0) {
                this.tempoExpirado = true;
                return;
            }
            clearInterval(this.intervalo);
            this.intervalo = setInterval(() => {
                if (tempoParaAgendar <= 0) {
                    this.tempoExpirado = true;
                    clearInterval(this.intervalo);
                } else {
                    tempoParaAgendar--;
                }
            }, 1000);
        }
    },
    beforeMount() {
        this.monitorarTempoConfirmacao();
    }
};
</script>

<style lang="scss" scoped>
.card-cnt {
    box-sizing: border-box;
    padding: v-bind(padding);
    border: 1px solid #cbd5e1;
    border-radius: 8px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
</style>
