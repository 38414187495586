<template>
    <div class="border-round-2xl bg-bluegray-50 p-4 w-full">
        <SecaoExpansivel size="text-3xl" title="Filiais" :quantity="filiais.length" expanded>
            <SecaoExpansivel
                v-for="(filial, index) of filiais"
                :key="filial.company_id"
                :ref="`filial-${filial.company_id}`"
                :quantity="+filial.quantidade_agendamentos_pendentes"
                use-tag
                icon="pi pi-building"
                :title="filial.company_name"
                @loadContent="carregarFuncionarios(filial.company_id, index)"
            >
                <span class="flex flex-column gap-4 pt-2 ml-3 md:ml-6">
                    <span class="text-2xl">Funcionários</span>
                    <CardFuncionario v-for="funcionario of filial.funcionarios" :key="funcionario.id_agendamento" :funcionario="funcionario" />
                </span>
            </SecaoExpansivel>
        </SecaoExpansivel>
    </div>
</template>

<script>
import SecaoExpansivel from './components/SecaoExpansivel.vue';
import CardFuncionario from './components/CardFuncionario/Index.vue';
import { getPrestadorToken } from '@/common/storage';
import { getClientBase } from '@/services/http';
import { logoutPrestador } from '@/services/auth';

export default {
    components: { SecaoExpansivel, CardFuncionario },
    data() {
        return {
            filiais: []
        };
    },
    methods: {
        async carregarFiliais() {
            try {
                const prestadorToken = getPrestadorToken();
                const authToken = prestadorToken ? { Authorization: `Bearer ${prestadorToken}` } : {};
                const { data } = await getClientBase(authToken).get('/acesso-prestador/quantidade-agendamentos-company');
                this.filiais = data;
            } catch (error) {
                if (error.response.data.statusCode === 401) {
                    logoutPrestador();
                    await this.$router.push('/confirmacao-prestador/');
                }
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro ao processar sua solicitação',
                    detail: error.response.data.message,
                    life: 3000
                });
            }
        },
        async carregarFuncionarios(companyId, index) {
            this.$refs[`filial-${companyId}`].loading = true;
            try {
                const prestadorToken = getPrestadorToken();
                const authToken = prestadorToken ? { Authorization: `Bearer ${prestadorToken}` } : {};
                const { data } = await getClientBase(authToken).get(`/acesso-prestador/funcionarios-exames/agendamentos/pendentes/${companyId}`);
                this.filiais[index].funcionarios = data;
            } catch (error) {
                if (error.response.data.statusCode === 401) {
                    logoutPrestador();
                    await this.$router.push('/confirmacao-prestador/');
                }
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro ao processar sua solicitação',
                    detail: error.response.data.message,
                    life: 3000
                });
            }
            this.$refs[`filial-${companyId}`].loading = false;
        }
    },
    async mounted() {
        await this.carregarFiliais();
    }
};
</script>

<style lang="scss" scoped>
:deep(.p-panel.p-panel-toggleable .p-panel-header) {
    padding-left: 0;
}
</style>
