<template>
    <Panel ref="menu" toggleable collapsed>
        <template #header>
            <div class="flex align-items-center gap-2" :class="{ 'p-3 pl-4 md:px-5': !transparentHeader }">
                <div class="flex align-items-center gap-2">
                    <i v-if="icon" :class="icon" />
                    <span :class="size"> {{ title }} </span>
                </div>
                <span
                    v-if="isNumber(quantity) && useTag"
                    class="px-2 md:px-3 py-2 md:ml-3 w-max"
                    style="border-radius: 2px; color: #653e11; background: #f6dfc3; font-family: Poppins-Regular, sans-serif"
                >
                    {{ statusLabel }}
                </span>
                <span
                    v-else-if="isNumber(quantity)"
                    class="border-1 border-bluegray-100 border-round flex align-items-center justify-content-center text-bluegray-800"
                    style="width: 25px; height: 25px; font-family: Poppins-Regular, sans-serif"
                >
                    {{ quantity }}
                </span>
            </div>
        </template>
        <template #icons>
            <button class="p-panel-header-icon p-link" :disabled="loading" @click="toggle">
                <span
                    v-if="!loading"
                    :class="`pi pi-angle-${$refs.menu ? ($refs.menu['d_collapsed'] ? 'down' : 'up') : expanded ? 'up' : 'down'} md:text-2xl`"
                />
                <i v-else class="pi pi-spin pi-spinner" style="font-size: 1rem" />
            </button>
        </template>
        <template #default>
            <slot />
        </template>
    </Panel>
</template>

<script>
import { isNumber } from 'chart.js/helpers';

export default {
    inject: ['quantidadeConfirmados'],
    emits: ['loadContent'],
    props: {
        icon: String,
        title: { type: String, required: true },
        size: String,
        transparentHeader: { type: Boolean, default: false },
        expanded: { type: Boolean, default: false },
        quantity: Number,
        useTag: { type: Boolean, default: false }
    },
    data() {
        return {
            loading: false
        };
    },
    computed: {
        panelHeaderBackgroundColor() {
            return this.transparentHeader ? 'transparent' : 'white';
        },
        panelHeaderBorder() {
            return this.transparentHeader ? 'none' : '1px solid #cbd5e1';
        },
        margin() {
            return this.transparentHeader ? '43px' : '0';
        },
        quantidadeAjustada() {
            return this.quantidadeConfirmados ? this.quantity - this.quantidadeConfirmados : this.quantity;
        },
        statusLabel() {
            let status = 'pendentes';
            if (this.quantidadeAjustada === 1) {
                status = status
                    .split(' ')
                    .map((palavra) => palavra.replace(/s$/, ''))
                    .join(' ');
            }
            return `${this.quantidadeAjustada} ${status.toUpperCase()}`;
        },
        isExpanded() {
            return this.$refs.menu ? !this.$refs.menu['d_collapsed'] : this.expanded;
        }
    },
    methods: {
        isNumber,
        toggle(event) {
            this.$refs.menu.toggle(event);
            if (this.isExpanded) {
                this.$emit('loadContent');
            }
        }
    },
    mounted() {
        if (this.expanded) {
            this.toggle();
        }
    }
};
</script>

<style lang="scss" scoped>
@font-face {
    font-family: Poppins-Regular;
    src: url('/fonts/poppins/Poppins-Regular.ttf');
}

@font-face {
    font-family: Poppins-Bold;
    src: url('/fonts/poppins/Poppins-Bold.ttf');
}

@font-face {
    font-family: Poppins-Medium;
    src: url('/fonts/poppins/Poppins-Medium.ttf');
}

:deep(.p-panel-header),
:deep(.p-panel-content) {
    font-family: Poppins-Bold, sans-serif;
    border: none;
    border-radius: 8px;
    margin-left: v-bind(margin);
}

:deep(.p-panel-header) {
    background-color: v-bind(panelHeaderBackgroundColor);
    border: v-bind(panelHeaderBorder);
    margin-bottom: 12px;
}

:deep(.p-panel-content) {
    background-color: transparent;
    position: relative;
    padding: 0;
}

:deep(.p-panel-toggler) {
    display: none;
}
</style>
