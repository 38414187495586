<template>
    <div class="flex flex-column text-bluegray-800">
        <span v-if="confirmado" class="border-1 border-green-500 border-round-xl flex justify-content-center align-items-center gap-2 p-3 mb-2">
            <i class="pi pi-check-square text-green-500 text-2xl" />
            <span class="text-lg" style="font-family: Poppins-Medium, sans-serif">Agendado com sucesso</span>
        </span>
        <span
            v-else-if="aguardandoConfirmacaoFuncionario"
            class="border-1 border-bluegray-200 border-round-xl flex justify-content-center align-items-center gap-2 p-3 mb-2"
        >
            <i class="pi pi-info-circle text-primary text-2xl" />
            <span class="text-md" style="font-family: Poppins-Medium, sans-serif">Aguardando conf. do funcionário</span>
        </span>
        <span
            v-else-if="tempoExpirado"
            class="border-1 border-pink-500 border-round-xl flex justify-content-center align-items-center gap-2 p-3 mb-2"
        >
            <i class="pi pi-clock text-pink-500 text-2xl" />
            <span class="text-lg" style="font-family: Poppins-Medium, sans-serif">Tempo expirado</span>
        </span>
        <div class="flex justify-content-between align-items-center gap-3">
            <span class="flex align-items-center gap-2">
                <i class="pi pi-id-card" />
                <span>{{ capitalize(funcionario.nome_funcionario) }}</span>
            </span>
            <span class="flex gap-3 align-items-center">
                <Button
                    badge-class="bg-red-100"
                    label="Mais detalhes"
                    icon="pi pi-plus-circle"
                    iconPos="left"
                    class="p-button-text font-semibold text-bluegray-800 px-0"
                    @click="acessarDetalhes"
                    :loading="loading"
                />
            </span>
        </div>

        <div v-if="!confirmado && !aguardandoConfirmacaoFuncionario" class="mt-2">
            <div class="flex flex-column gap-2">
                <FuncionarioMobileExame
                    v-for="procedimento of funcionario.procedimentos"
                    :key="procedimento.id"
                    :ref="`procedimento-${procedimento.id}`"
                    :modoExibicao="modoExibicao"
                    :procedimento="procedimento"
                    :dadosAgendamento="dadosAgendamento"
                    :tempoExpirado="tempoExpirado"
                    :periodo-agendamento="funcionario.periodo_agendamento"
                />
            </div>
            <div v-if="!modoExibicao" class="w-full flex justify-content-end mt-2">
                <Button
                    label="Confirmar"
                    class="p-button p-button-text p-button-rounded text-lg"
                    style="color: #3f51b5; font-family: Poppins-Regular, sans-serif; font-weight: 600"
                    @click="confirmarAgendamento"
                    :disabled="tempoExpirado"
                    :loading="loading"
                />
            </div>
            <Toast />
        </div>
    </div>
    <DialogDetalhes :funcionario="funcionario" :confirmado="confirmado || aguardandoConfirmacaoFuncionario || tempoExpirado" ref="dialogDetalhes" />
</template>

<script>
import DialogDetalhes from '../../../../DialogDetalhes/Index.vue';
import { logoutPrestador } from '@/services/auth';
import { getPrestadorToken } from '@/common/storage';
import { getClientBase } from '@/services/http';
import moment from 'moment-timezone';
import FuncionarioMobileExame from './FuncionarioMobileExame.vue';
export default {
    inject: ['confirmar'],
    emits: ['confirmado'],
    components: { FuncionarioMobileExame, DialogDetalhes },
    props: {
        modoExibicao: { type: Boolean, default: false },
        funcionario: { type: Object, required: true },
        confirmado: Boolean,
        tempoExpirado: Boolean,
        aguardandoConfirmacaoFuncionario: Boolean
    },
    data() {
        return {
            loading: false,
            data: null,
            hora: null
        };
    },
    computed: {
        dadosAgendamento() {
            const { id_agendamento, periodo_agendamento, data_sugestao_inicial, data_sugestao_final } = this.funcionario;
            return {
                id_agendamento,
                periodo_agendamento,
                data_sugestao_inicial: moment(data_sugestao_inicial).toDate(),
                data_sugestao_final: moment(data_sugestao_final).toDate()
            };
        }
    },
    methods: {
        capitalize(string) {
            return string
                .split(' ')
                .map((palavra) => palavra[0].toUpperCase() + palavra.slice(1).toLowerCase())
                .join(' ');
        },
        async acessarDetalhes() {
            this.loading = true;
            await this.$refs.dialogDetalhes.acessarDetalhes();
            this.loading = false;
        },
        async confirmarAgendamento() {
            try {
                const procedimentos = this.funcionario.procedimentos.map((procedimento) => {
                    return {
                        dataAgendamento: this.$refs[`procedimento-${procedimento.id}`]._data,
                        horaAgendamento: this.$refs[`procedimento-${procedimento.id}`]._hora,
                        agendamentoId: this.funcionario.id_agendamento,
                        id: procedimento.id_agendamento_procedimento
                    };
                });
                if (procedimentos.some(({ dataAgendamento, horaAgendamento }) => !dataAgendamento || !horaAgendamento)) {
                    return this.$toast.add({
                        severity: 'error',
                        summary: 'Campos Inválidos',
                        detail: 'Há campos de data ou hora não preenchidos',
                        life: 3000
                    });
                }
                this.loading = true;
                const prestadorToken = getPrestadorToken();
                const authToken = prestadorToken ? { Authorization: `Bearer ${prestadorToken}` } : {};
                await getClientBase(authToken).post('/acesso-prestador/confirmar-procedimento', procedimentos);
                this.$toast.add({
                    severity: 'success',
                    summary: 'Agendamento Confirmado',
                    detail: `Agendamento de ${this.funcionario.nome_funcionario} confirmado com sucesso!`,
                    life: 3000
                });
                this.$emit('confirmado');
                this.confirmar();
            } catch (error) {
                if (error.response.data.statusCode === 401) {
                    logoutPrestador();
                    await this.$router.push('/confirmacao-prestador/');
                }
                if (error.response.data.statusCode === 408) {
                    this.$toast.add({
                        severity: 'error',
                        summary: 'Erro!',
                        detail: 'Sistema indisponível, tente novamente mais tarde.',
                        life: 3000
                    });
                }
                if (error.response.data.details.response.code === 409) {
                    this.$toast.add({
                        severity: 'info',
                        summary: 'Informações desatualizadas!',
                        detail: error.response.data.details.response.message,
                        life: 3000
                    });
                    this.loading = false;
                    return;
                }
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro ao processar sua solicitação',
                    detail: error.response.data.message,
                    life: 3000
                });
            }
            this.loading = false;
        }
    }
};
</script>

<style lang="scss" scoped>
$rosa-primario: #3f51b5;

@font-face {
    font-family: Poppins-Medium;
    src: url('/fonts/poppins/Poppins-Medium.ttf');
}

:deep(.p-button-icon) {
    color: $rosa-primario;
}

.rosa-primario {
    color: $rosa-primario;
}
</style>
