<template>
    <div class="flex justify-content-between align-items-center gap-3 text-bluegray-800">
        <span class="flex align-items-center gap-2">
            <i class="pi pi-id-card" />
            <span>{{ capitalize(funcionario.nome_funcionario) }}</span>
            <span v-if="confirmado" class="border-1 border-green-500 border-round-xl flex align-items-center gap-2 ml-2 p-3">
                <i class="pi pi-check-square text-green-500 text-2xl" />
                <span class="text-lg" style="font-family: Poppins-Medium, sans-serif">Agendado com sucesso</span>
            </span>
            <span
                v-else-if="aguardandoConfirmacaoFuncionario"
                class="border-1 border-bluegray-200 border-round-xl flex align-items-center gap-2 ml-2 p-3"
            >
                <i class="pi pi-info-circle text-primary text-2xl" />
                <span class="text-lg" style="font-family: Poppins-Medium, sans-serif">Aguardando confirmação do funcionário</span>
            </span>
            <span v-else-if="tempoExpirado" class="border-1 border-pink-500 border-round-xl flex align-items-center gap-2 ml-2 p-3">
                <i class="pi pi-clock text-pink-500 text-2xl" />
                <span class="text-lg" style="font-family: Poppins-Medium, sans-serif">Tempo expirado</span>
            </span>
        </span>
        <span class="flex gap-3 align-items-center">
            <span>{{ mensagemPeriodo }}</span>
            <Button
                badge-class="bg-red-100"
                label="Mais detalhes"
                icon="pi pi-plus-circle"
                iconPos="left"
                class="p-button-text font-semibold text-bluegray-800 px-0"
                @click="acessarDetalhes"
                :loading="loading"
            />
        </span>
    </div>
    <DialogDetalhes :funcionario="funcionario" :confirmado="confirmado || aguardandoConfirmacaoFuncionario || tempoExpirado" ref="dialogDetalhes" />
</template>

<script>
import DialogDetalhes from '../../../../DialogDetalhes/Index.vue';

export default {
    components: { DialogDetalhes },
    props: {
        funcionario: { type: Object, required: true },
        confirmado: Boolean,
        tempoExpirado: Boolean,
        aguardandoConfirmacaoFuncionario: Boolean
    },
    data() {
        return {
            loading: false
        };
    },
    computed: {
        mensagemPeriodo() {
            const periodos = {
                1: 'Manhã',
                2: 'Tarde',
                3: 'Ambos'
            };

            return `Período de preferência: ${periodos[this.funcionario.periodo_agendamento]}`;
        }
    },
    methods: {
        capitalize(string) {
            return string
                .split(' ')
                .map((palavra) => palavra[0].toUpperCase() + palavra.slice(1).toLowerCase())
                .join(' ');
        },
        async acessarDetalhes() {
            this.loading = true;
            await this.$refs.dialogDetalhes.acessarDetalhes();
            this.loading = false;
        }
    }
};
</script>

<style lang="scss" scoped>
$rosa-primario: #3f51b5;

@font-face {
    font-family: Poppins-Medium;
    src: url('/fonts/poppins/Poppins-Medium.ttf');
}

:deep(.p-button-icon) {
    color: $rosa-primario;
}
</style>
